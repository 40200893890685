import React from 'react'
import features from '../Image/features.svg'
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import backimage from '../Image/backimage.svg'
import Contact from './Contact';

const Workshop = () => {
  return (
    <>
    <div className='about-first bg-light'>
        <div className='container'>
            <p className='mb-3 text-dark'><Link className='text-dark link-home' to={`/home`}>Home // </Link><span>Our Workshop </span></p>
            <h1 className='mb-3'><span>Our </span>Workshop</h1>
        </div>
    </div>
    <div className='container home-tow' id='Electronics'>
        <div className='tow-text text-center mb-lg-5 mb-sm-3'>
            <h2 className='mb-3'><span>Electronics </span>(Arduino)</h2>
        </div>
        <div className='row '>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>In our Electronics course, students delve into the fundamentals of electronic circuits, learning about components, circuit design, and troubleshooting techniques. They gain a deep understanding of analog and digital electronics, enabling them to create and analyze complex circuits.</p>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow'>
        <div className='row '>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>In the Electronics (Arduino) course, students delve deeper into Arduino, a versatile microcontroller platform widely used in electronics projects. They learn advanced programming techniques and explore applications such as home automation, robotics, and sensor interfacing.</p>
                </div>
            </div>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow' id='Iot'>
        <div className='tow-text text-center mb-lg-5 mb-sm-3'>
            <h2 className='mb-3'><span>IOT </span>(ESP8266) (Raspberry pi)</h2>
        </div>
        <div className='row '>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>The IoT (ESP8266) (Raspberry Pi) course takes students on a journey to create IoT systems using popular platforms like ESP8266 and Raspberry Pi. They gain hands-on experience in developing interconnected devices, designing cloud-based applications, and integrating sensors for data acquisition.</p>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow'>
        <div className='row '>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                <p>The IoT course focuses on the integration of physical devices with the digital world, enabling students to build smart systems that collect and analyze data. They learn about popular IoT platforms such as Arduino, ESP8266, and Raspberry Pi, gaining expertise in connecting sensors, actuators, and communication protocols.</p>
                </div>
            </div>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow' id='Robotics'>
        <div className='tow-text text-center mb-lg-5 mb-sm-3'>
            <h2 className='mb-3'><span>Robot</span>ics</h2>
        </div>
        <div className='row '>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                <p>Our Robotics course introduces students to the fascinating world of robotics, where they explore the principles of mechanical design, programming, and control systems. Through hands-on projects, students develop the ability to construct and program robots capable of performing various tasks autonomously.</p>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow'>
        <div className='row '>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                <p>Our Robotics course introduces students to the fascinating world of robotics, where they explore the principles of mechanical design, programming, and control systems. Through hands-on projects, students develop the ability to construct and program robots capable of performing various tasks autonomously.</p>
                </div>
            </div>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow' id='Drown'>
        <div className='tow-text text-center mb-lg-5 mb-sm-3'>
            <h2 className='mb-3'><span>Drown</span></h2>
        </div>
        <div className='row '>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>Our Embedded Systems course focuses on the design and development of embedded systems, combining hardware and software. Students gain hands-on experience in programming microcontrollers, interfacing with sensors and actuators, and building real-time systems.</p>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow'>
        <div className='row '>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>Our Embedded Systems course focuses on the design and development of embedded systems, combining hardware and software. Students gain hands-on experience in programming microcontrollers, interfacing with sensors and actuators, and building real-time systems.</p>
                </div>
            </div>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow' id='DesignPrinting'>
        <div className='tow-text text-center mb-lg-5 mb-sm-3'>
            <h2 className='mb-3'><span>3D Design </span>and Printing</h2>
        </div>
        <div className='row '>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>Our 3D Design and Printing course introduces students to the fascinating world of 3D modeling and additive manufacturing. They learn to create intricate designs using CAD software and bring them to life through 3D printing technology.</p>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow'>
        <div className='row '>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                <p>Our 3D Design and Printing course introduces students to the fascinating world of 3D modeling and additive manufacturing. They learn to create intricate designs using CAD software and bring them to life through 3D printing technology.</p>
                </div>
            </div>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow' id='Blockcoding'>
        <div className='tow-text text-center mb-lg-5 mb-sm-3'>
            <h2 className='mb-3'><span>Block </span>coding</h2>
        </div>
        <div className='row '>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>In the Block Coding course, students learn the basics of programming using visual coding languages. They develop computational thinking skills and create interactive projects and games.</p>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow'>
        <div className='row '>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>In the Block Coding course, students learn the basics of programming using visual coding languages. They develop computational thinking skills and create interactive projects and games.</p>
                </div>
            </div>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow' id='AppDevelopment'>
        <div className='tow-text text-center mb-lg-5 mb-sm-3'>
            <h2 className='mb-3'><span>App </span>Development</h2>
        </div>
        <div className='row '>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>The App Development course equips students with the knowledge and skills to create mobile applications for iOS and Android platforms. They learn programming languages such as Swift and Java and explore app design principles and user experience.</p>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow'>
        <div className='row '>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>The App Development course equips students with the knowledge and skills to create mobile applications for iOS and Android platforms. They learn programming languages such as Swift and Java and explore app design principles and user experience.</p>
                </div>
            </div>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow' id='ARVR'>
        <div className='tow-text text-center mb-lg-5 mb-sm-3'>
            <h2 className='mb-3'><span>AR / VR </span>Tech</h2>
        </div>
        <div className='row '>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>Our AR/VR Tech course introduces students to the exciting realms of augmented reality and virtual reality. They learn to create immersive experiences, develop 3D environments, and interact with virtual objects.</p>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow'>
        <div className='row '>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>In the AR/VR course, students dive deeper into augmented reality and virtual reality technologies. They explore advanced concepts such as spatial computing, gesture recognition, and immersive storytelling.</p>
                </div>
            </div>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
        </div>
    </div>
    
    <div className='home-tow'></div>
        <div className='foot-color'>
        </div>
        <div className='bg-dark'>
            <div className='container'>
                <div className='py-2 row justify-content-between'>
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                    <p className='mb-0'> © 2021-2024 ElectroEra PVT LTD. All Rights Reserved.</p>
                    </div>
                    
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                        <p className='mb-0'>Design by <Link target={'_blank'} to={`https://mechodal.com/`}>Mechodal Technology</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Workshop
