import React from 'react'
import features from '../Image/features.svg'
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import backimage from '../Image/backimage.svg'
import Contact from './Contact';
import woweare from '../Image/woweare.png'
import vision from '../Image/vision.png'
import mission from '../Image/mission.png'

const Service = () => {
  return (
    <>
    <div className='about-first bg-light'>
        <div className='container'>
            <p className='mb-3 text-dark'><Link className='text-dark link-home' to={`/home`}>Home // </Link><span>Our Services </span></p>
            <h1 className='mb-3'><span>Our </span>Services</h1>
        </div>
    </div>
    {/* <div class="software sticky-top top-header nav-shad bg-dark">
        <div class="container">
            <div class="d-flex header-three">
                <a href="#Equipment">Equipment Supply</a>
                <a href="#Teachertraining">Teachers Training</a>
                <a href="#Studenttraining">Students Training</a>
                <a href="#Mastertrainer">Master Trainer</a>
                <a href="#Lebsetup">Leb Set-Up</a>
                <a href="#Ourservice">Our Services</a>
            </div>
        </div>
    </div> */}
        {/* <div className='container home-tow' id='Lebsetup'>
            <div className='tow-text text-center mb-lg-5 mb-sm-3'>
                <h2 className='mb-3'><span>Leb </span>Set-Up</h2>
            </div>
            <div className='row '>
                <div className='col-lg-6 col-md-12 my-3'>
                    <div className='about-sec'>
                        <h5>We Provide Master Trainer</h5>                     
                        <h2>We provide Master trainers keeping in mind the requirement of schools / colleges / industries</h2>
                    </div>
                </div>
                <div className='col-lg-6 col-md-10 my-3'>
                    <div className='about-img'>
                      <img className='img-fluid' src={features}/>
                    </div>
                </div>
            </div>
        </div> */}
        <div className='container home-tow' id='Ourservice'>
            {/* <div className='tow-text text-center mb-lg-5 mb-sm-3'>
                <h2><span>Our </span>Services</h2>
            </div> */}
            <div className='row'>
                <div className='col-lg-3 col-md-12 mb-5'>
                    <div className='tow-boxx'>
                        <h2 className='text-center'>Equipment Supply</h2>
                        <p className='mb-0'>Best in Class Equipment Supply for STEM Labs, Robotics Labs, ATL Labs including
Package-1(Robotics, IoT, Electronics), Package-2 (Rapid Prototyping) Package-3
(Mechanical), and Package-4 (Safety). All our Branded Equipments come with 2
Years Warranty (Terms and Conditions Apply)
</p>
                        <NavLink to="/Contact" className='round-contact'>
                            <h1 className='mb-0'><i class="fa-solid fa-arrow-right"></i></h1>
                        </NavLink>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12 mb-5'>
                    <div className='tow-boxx'>
                        <h2 className='text-center'>Students & Teachers Training</h2>
                        <p className='mb-0'>At ElectroEra, our student training programs are designed to inspire and prepare the
next generation of tech professionals. Through hands-on experiences and practical
projects, we offer training in areas like Electronics, IoT, 3D Design, Robotics, App
development, AR/VR, and more. Our focused courses cater to specific interests, be it
mobile app development, AI, or other cutting-edge topics, providing in-depth
knowledge and hands-on practice to master the subject. We also value teachers
immensely and offer training to empower them with technical skills and innovative
teaching strategies. Our workshops cover curriculum development, instructional
techniques, and integrating technology into teaching. Kudos to all teachers for
shaping future innovators!
</p>
                        <NavLink to="/Contact" className='round-contact'>
                            <h1 className='mb-0'><i class="fa-solid fa-arrow-right"></i></h1>
                        </NavLink>
                    </div>
                </div>
                <div className='col-lg-3 col-md-12 mb-5'>
                    <div className='tow-boxx'>
                        <h2 className='text-center'>Provide STEM Trainer </h2>
                        <p className='mb-0'>We provide Master Trainers in the fields of Electronics, IoT, 3D Design & Printing,
Robotics, Drone Technology, App Development, AR/VR Technology, Embedded
System, etc. keeping in mind the needs of schools/colleges/industries.</p>
                        <NavLink to="/Contact" className='round-contact'>
                            <h1 className='mb-0'><i class="fa-solid fa-arrow-right"></i></h1>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
        
    <div className='home-tow'></div>

        <div className='foot-color'>
        </div>
        <div className='bg-dark'>
            <div className='container'>
                <div className='py-2 row justify-content-between'>
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                    <p className='mb-0'> © 2021-2024 ElectroEra PVT LTD. All Rights Reserved.</p>
                    </div>
                    
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                        <p className='mb-0'>Design by <Link target={'_blank'} to={`https://mechodal.com/`}>Mechodal Technology</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Service
