import React from 'react'
import features from '../Image/features.svg'
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import backimage from '../Image/backimage.svg'

const Teachertraining = () => {
  return (
    <>
    <div className='about-first bg-light'>
            <div className='container'>
            <p className='mb-3 text-dark'><Link className='text-dark link-home' to={`/home`}>Home // </Link><span>Training </span>//<span>Services</span> // <span>Teachers</span></p>
                <h1 className='mb-3'><span>Teachers </span>Training</h1>
            </div>
    </div>
    <div className='container home-tow'>
            <div className='row '>
                <div className='col-lg-6 col-md-10 my-3'>
                    <div className='about-img'>
                      <img className='img-fluid' src={features}/>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12 my-3'>
                    <div className='about-sec'>
                        <h5>We Provide Technical Training</h5>
                        <p>We understand the crucial role teachers play in shaping the minds of future innovators, hartly salute to all teachers. Our teacher training programs focus on empowering educators with the necessary technical skills and pedagogical strategies to effectively teach technology-related subjects. We offer workshops and courses that cover curriculum development, instructional techniques, Innovation mindset & new generation skill development strategy, and the integration of technology into teaching practices.</p>
                    </div>
                </div>
            </div>
        </div>
        
    <div className='home-tow'></div>

        <div className='foot-color'>
        </div>
        <div className='bg-dark'>
            <div className='container'>
                <div className='py-2 row justify-content-between'>
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                    <p className='mb-0'> © 2021-2024 ElectroEra PVT LTD. All Rights Reserved.</p>
                    </div>
                    
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                        <p className='mb-0'>Design by <Link target={'_blank'} to={`https://mechodal.com/`}>Mechodal Technology</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Teachertraining