import work1 from '../Image/work1.png'
import work2 from '../Image/work2.png'
import work3 from '../Image/work3.png'
import work4 from '../Image/work4.png'
import work5 from '../Image/work5.png'
import work6 from '../Image/work6.png'
import work7 from '../Image/work7.png'
import work8 from '../Image/work8.png'
import work9 from '../Image/work9.png'
import work10 from '../Image/work10.png'
import work11 from '../Image/work11.png'
import work12 from '../Image/work12.png'
import work13 from '../Image/work13.png'
import work14 from '../Image/work14.png'
import work15 from '../Image/work15.png'
import work16 from '../Image/work16.png'
import lab1 from '../Image/lab1.png'
import lab2 from '../Image/lab2.png'
import lab3 from '../Image/lab3.png'
import lab4 from '../Image/lab4.png'
import lab5 from '../Image/lab5.png'
import lab6 from '../Image/lab6.png'
import projects1 from '../Image/projects1.png'
import projects2 from '../Image/projects2.png'
import projects3 from '../Image/projects3.png'
import projects4 from '../Image/projects4.png'
import projects5 from '../Image/projects5.png'
import projects6 from '../Image/projects6.png'
import projects7 from '../Image/projects7.png'
import projects8 from '../Image/projects8.png'
import projects9 from '../Image/projects9.png'
import projects10 from '../Image/projects10.png'
import projects11 from '../Image/projects11.png'
import projects12 from '../Image/projects12.png'
import projects13 from '../Image/projects13.png'
import projects14 from '../Image/projects14.png'
import projects15 from '../Image/projects15.png'
import projects16 from '../Image/projects16.png'
import celebration1 from '../Image/celebration1.png'
import celebration2 from '../Image/celebration2.png'
import celebration3 from '../Image/celebration3.png'
import celebration4 from '../Image/celebration4.png'
import celebration5 from '../Image/celebration5.png'
import celebration6 from '../Image/celebration6.png'
import celebration7 from '../Image/celebration7.png'
import celebration8 from '../Image/celebration8.png'
import celebration9 from '../Image/celebration9.png'
import celebration10 from '../Image/celebration10.png'
import celebration11 from '../Image/celebration11.png'
import celebration12 from '../Image/celebration12.png'


const Imgapi = [
    {
        id:18,
        image:lab1,
        heading:"Bhavnagar",
        category:"Labsetup",
    },
    {
        id:27,
        image:projects1,
        heading:"Students Project",
        category:"Projects",
    },
    {
        id:24,
        image:celebration1,
        heading:"Celebration",
        category:"Celebration",
    },
    {
        id:16,
        image:work1,
        heading:"3D Designing & Printing",
        category:"Workshop",
    },
    {
        id:3,
        image:work3,
        heading:"3D Designing & Printing",
        category:"Workshop",
    },
    {
        id:22,
        image:lab5,
        heading:"Bhavnagar",
        category:"Labsetup",
    },
    {
        id:1,
        image:work6,
        heading:"Coding",
        category:"Workshop",
    },
    {
        id:4,
        image:work4,
        heading:"AR/VR Technology",
        category:"Workshop",
    },
    {
        id:5,
        image:work5,
        heading:"AR/VR Technology",
        category:"Workshop",
    },
    {
        id:17,
        image:work2,
        heading:"3D Designing & Printing",
        category:"Workshop",
    },
    {
        id:6,
        image:work16,
        heading:"Workshop",
        category:"Workshop",
    },
    {
        id:7,
        image:work7,
        heading:"Drone Technology",
        category:"Workshop",
    },
    {
        id:8,
        image:work8,
        heading:"Drone Technology",
        category:"Workshop",
    },
    {
        id:9,
        image:work9,
        heading:"Electronics",
        category:"Workshop",
    },
    {
        id:10,
        image:work10,
        heading:"MIT App Development",
        category:"Workshop",
    },
    {
        id:11,
        image:work11,
        heading:"Robotics",
        category:"Workshop",
    },
    {
        id:12,
        image:work12,
        heading:"Workshop",
        category:"Workshop",
    },
    {
        id:13,
        image:work13,
        heading:"Workshop",
        category:"Workshop",
    },
    {
        id:14,
        image:work14,
        heading:"Workshop",
        category:"Workshop",
    },
    {
        id:15,
        image:work15,
        heading:"Workshop",
        category:"Workshop",
    },
    {
        id:19,
        image:lab2,
        heading:"Bhavnagar",
        category:"Labsetup",
    },
    {
        id:20,
        image:lab3,
        heading:"Bhavnagar",
        category:"Labsetup",
    },
    {
        id:21,
        image:lab4,
        heading:"Bhavnagar",
        category:"Labsetup",
    },
    {
        id:23,
        image:lab6,
        heading:"Mahuva",
        category:"Labsetup",
    },
    {
        id:25,
        image:celebration2,
        heading:"Celebration",
        category:"Celebration",
    },
    {
        id:26,
        image:celebration3,
        heading:"Celebration",
        category:"Celebration",
    },
    {
        id:34,
        image:celebration4,
        heading:"Celebration",
        category:"Celebration",
    },
    {
        id:35,
        image:celebration5,
        heading:"Celebration",
        category:"Celebration",
    },
    {
        id:36,
        image:celebration6,
        heading:"Celebration",
        category:"Celebration",
    },
    {
        id:37,
        image:celebration7,
        heading:"Celebration",
        category:"Celebration",
    },
    {
        id:38,
        image:celebration8,
        heading:"Celebration",
        category:"Celebration",
    },
    {
        id:39,
        image:celebration9,
        heading:"Celebration",
        category:"Celebration",
    },
    {
        id:40,
        image:celebration10,
        heading:"Celebration",
        category:"Celebration",
    },
    {
        id:41,
        image:celebration11,
        heading:"Celebration",
        category:"Celebration",
    },
    {
        id:42,
        image:celebration12,
        heading:"Celebration",
        category:"Celebration",
    },
    {
        id:28,
        image:projects2,
        heading:"Students Project",
        category:"Projects",
    },
    {
        id:29,
        image:projects3,
        heading:"Students Project",
        category:"Projects",
    },
    {
        id:30,
        image:projects4,
        heading:"Students Project",
        category:"Projects",
    },
    {
        id:31,
        image:projects5,
        heading:"Students Project",
        category:"Projects",
    },
    {
        id:32,
        image:projects6,
        heading:"Students Project",
        category:"Projects",
    },
    {
        id:2,
        image:projects7,
        heading:"Students Project",
        category:"Projects",
    },
    {
        id:43,
        image:projects13,
        heading:"Students Project",
        category:"Projects",
    },
    {
        id:44,
        image:projects8,
        heading:"Students Project",
        category:"Projects",
    },
    {
        id:45,
        image:projects9,
        heading:"Students Project",
        category:"Projects",
    },
    {
        id:46,
        image:projects10,
        heading:"Students Project",
        category:"Projects",
    },
    {
        id:47,
        image:projects11,
        heading:"Students Project",
        category:"Projects",
    },
    {
        id:48,
        image:projects12,
        heading:"Students Project",
        category:"Projects",
    },
    {
        id:49,
        image:projects14,
        heading:"Students Project",
        category:"Projects",
    },
    {
        id:50,
        image:projects15,
        heading:"Students Project",
        category:"Projects",
    },
    {
        id:51,
        image:projects16,
        heading:"Students Project",
        category:"Projects",
    },
]

export default Imgapi