import React from 'react'
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import supporterone from '../Image/supporterone.svg'
import supportertwo from '../Image/supportertwo.svg'
import supporterthree from '../Image/supporterthree.svg'
import supporterfour from '../Image/supporterfour.svg'
import supporterfive from '../Image/supporterfive.svg'
import woweare from '../Image/woweare.png'
import vision from '../Image/vision.png'
import mission from '../Image/mission.png'
import backimage from '../Image/backimage.svg'
import features from '../Image/features.svg'
import about from '../Image/about.gif'

const Course = () => {
  return (
    <div>
        <div className='about-first bg-light'>
            <div className='container'>
                <p className='mb-3 text-dark'><Link className='text-dark link-home' to={`/home`}>Home // </Link><span>About us</span></p>
                <h1 className='mb-3'><span>About </span>ElectroEra</h1>
            </div>
        </div>
        <div className='container pt-5'>
            <div className='row justify-content-center'>
                <div className='col-12'>
                      <img className='img-fluid w-100' src={about}/>
                </div>
            </div>
        </div>
        
        <div className='container home-tow'>
            <div className='tow-text text-center mb-3 mb-md-5'>
                <h2 className='mb-3'><span>Why </span>choose Us</h2>
            </div>
            <div className='row'>
                <div className='col-lg-4 col-md-6 my-2'>
                    <div className='author-box px-3 py-4 h-100 custom-software-box'>
                        <div className='about-sec'>
                            <div className='mission-img text-center'>
                                <img className='img-fluid' src={woweare}/>
                            </div>
                            <h5 className='pt-3'>Who We Are</h5>
                            <p className='mb-0 pt-2 mt-0 text-justify'>ElectroEra Privet limited is an open electronic platform where students and youth work on their own creative ideas. We are working in the fields of Electronics, IoT, 3D Design & Printing, Robotics, Drone Technology, App development, AR/VR Technology, Embedded system etc. We conduct various seminars, workshops, courses, internships, Training to connect new generations with new technology, and give students knowledge of practical/industrial work, and establish innovative ideas in their minds.</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 my-2'>
                    <div className='author-box px-3 py-4 h-100 custom-software-box'>
                        <div className='about-sec'>
                            <div className='mission-img text-center'>
                                <img className='img-fluid' src={vision}/>
                            </div>
                            <h5 className='pt-3'>Our Vision</h5>
                            <p className='mb-0 pt-2 mt-0 text-justify'>Inspiring the innovative & Creative ideas to establish ElectroEra in the world.</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 my-2'>
                    <div className='author-box px-3 py-4 h-100 custom-software-box'>
                        <div className='about-sec'>
                            <div className='mission-img text-center'>
                                <img className='img-fluid' src={mission}/>
                            </div>
                            <h5 className='pt-3'>Our Mission</h5>
                            <ul>
                                <li>To generate innovative ideas in the minds of new generations and to introduce those ideas in the form of technology with the help of education.</li>
                                <li>Improvement Of Technical Education Skill with the help of Practical Knowledge.</li>
                                <li>Bringing change in the country over time by keeping Indian ideology together.</li>
                            </ul>                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className='container home-tow'>
            <div className='tow-text text-center mb-3 mb-md-5 mt-0 direction-text impact-font'>
                <h5 className='mb-3'><span>ElectroEra's </span>impact on society</h5>
            </div>
            <div className='row'>
                <div className='col-lg-4 col-md-6 my-2'>
                    <div className='tow-box'>
                        <div className='midal-text'>
                            <h1 className='mb-0'>1</h1>
                        </div>
                        <h2>Technological Advancements</h2>
                        <p className='mb-0'>ElectroEra's emphasis on diverse technological fields like
Electronics, IoT, Robotics, AR/VR, and more could lead to a wave of innovations. New
devices, systems, and applications could emerge, enhancing various industries and improving
quality of life.
</p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 my-2'>
                    <div className='tow-box'>
                        <div className='midal-text'>
                            <h1 className='mb-0'>2</h1>
                        </div>
                        <h2>Skilled Workforce</h2>
                        <p className='mb-0'>As ElectroEra continues to provide practical knowledge and hands-on
experience through seminars, workshops, and courses, there would likely be an increase in
the number of skilled individuals in emerging technologies. This could lead to a more capable
and adaptable workforce, capable of meeting industry demands.</p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 my-2'>
                    <div className='tow-box'>
                        <div className='midal-text'>
                            <h1 className='mb-0'>3</h1>
                        </div>
                        <h2>Innovative Startups </h2>
                        <p className='mb-0'>The culture of innovation and entrepreneurship fostered by
ElectroEra could lead to the emergence of numerous startups. Alumni and participants might
embark on their own ventures, further contributing to technological advancements and
economic growth.
</p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 my-2'>
                    <div className='tow-box'>
                        <div className='midal-text'>
                            <h1 className='mb-0'>4</h1>
                        </div>
                        <h2>Educational Reform</h2>
                        <p className='mb-0'> ElectroEra's efforts to infuse practical knowledge into the education
system could inspire broader educational reform. Other institutions might adopt similar
approaches, leading to more relevant and hands-on learning experiences.</p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 my-2'>
                    <div className='tow-box'>
                        <div className='midal-text'>
                            <h1 className='mb-0'>5</h1>
                        </div>
                        <h2>Economic Growth</h2>
                        <p className='mb-0'>The creation of startups, new technologies, and skilled professionals
would likely contribute to economic growth. ElectroEra's influence on entrepreneurship could
lead to the development of tech hubs and innovation centers.</p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 my-2'>
                    <div className='tow-box'>
                        <div className='midal-text'>
                            <h1 className='mb-0'>6</h1>
                        </div>
                        <h2>Societal Mindset Shift</h2>
                        <p className='mb-0'>ElectroEra's mission to encourage creative thinking and
problem-solving could lead to a broader societal mindset shift. People might become more
open to embracing new technologies and innovative solutions in their daily lives.</p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 my-2'>
                    <div className='tow-box'>
                        <div className='midal-text'>
                            <h1 className='mb-0'>7</h1>
                        </div>
                        <h2>Technological Solutions for Social Challenges</h2>
                        <p className='mb-0'>ElectroEra's focus on practical
applications of technology could lead to solutions for pressing societal challenges. Whether
it's healthcare, environment, or social justice, technology could be harnessed for positive
change.
</p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 my-2'>
                    <div className='tow-box'>
                        <div className='midal-text'>
                            <h1 className='mb-0'>8</h1>
                        </div>
                        <h2>Global Recognition</h2>
                        <p className='mb-0'>Through its commitment to innovation and creativity, ElectroEra
could gain international recognition as a hub for cutting-edge technological advancements
and education.</p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 my-2'>
                    <div className='tow-box'>
                        <div className='midal-text'>
                            <h1 className='mb-0'>9</h1>
                        </div>
                        <h2>Collaborative Ecosystem</h2>
                        <p className='mb-0'>ElectroEra's efforts might lead to the creation of a collaborative
ecosystem involving academia, industry, and government. This ecosystem could facilitate
research, development, and implementation of new technologies.
</p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 my-2'>
                    <div className='tow-box'>
                        <div className='midal-text'>
                            <h1 className='mb-0'>10</h1>
                        </div>
                        <h2>Youth Empowerment</h2>
                        <p className='mb-0'>ElectroEra's impact on young individuals could go beyond
technology. The skills, mindset, and confidence gained could empower youth to take on
leadership roles and contribute positively to society.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className='container home-two home-three'>
            <div className='direction-text'>
                <h5>नई <span>दिशाएं</span>  || नई <span>सोच</span> || नया <span>निर्माण </span></h5>
            </div>
        </div>

        <div className='foot-color'>
        </div>
        <div className='bg-dark'>
            <div className='container'>
                <div className='py-2 row justify-content-between'>
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                    <p className='mb-0'> © 2021-2024 ElectroEra PVT LTD. All Rights Reserved.</p>
                    </div>
                    
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                        <p className='mb-0'>Design by <Link target={'_blank'} to={`https://mechodal.com/`}>Mechodal Technology</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Course

{/* <div className='container home-tow'>
            <div className='row'>
                <div className='col-lg-4 col-md-6 my-2'>
                    <div className='work-boxx'>
                        <div className='d-flex align-items-center top-text'>
                            <img className='img-fluid me-3' src='./top-1.svg'/>
                            <h3>Top Instructors</h3>
                        </div>
                        <p>Lorem Ipsum has been the industry's standard dumy text since the when took and scrambled to make type specimen book has survived.</p>
                        <p className='mb-0'>Lorem Ipsum has been the industry's standard dumy text since the when took and scrambled make.</p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 my-2'>
                    <div className='work-boxx'>
                        <div className='d-flex align-items-center top-text'>
                            <img className='img-fluid me-3' src='./top-2.svg'/>
                            <h3>Portable Program</h3>
                        </div>
                        <p>Lorem Ipsum has been the industry's standard dumy text since the when took and scrambled to make type specimen book has survived.</p>
                        <p className='mb-0'>Lorem Ipsum has been the industry's standard dumy text since the when took and scrambled make.</p>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 my-2'>
                    <div className='work-boxx'>
                        <div className='d-flex align-items-center top-text'>
                            <img className='img-fluid me-3' src='./top-3.svg'/>
                            <h3>Improve Quickly</h3>
                        </div>
                        <p>Lorem Ipsum has been the industry's standard dumy text since the when took and scrambled to make type specimen book has survived.</p>
                        <p className='mb-0'>Lorem Ipsum has been the industry's standard dumy text since the when took and scrambled make.</p>
                    </div>
                </div>
            </div>
        </div> */}