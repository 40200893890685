import React from 'react'
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import supporterone from '../Image/supporterone.svg'
import supportertwo from '../Image/supportertwo.svg'
import supporterthree from '../Image/supporterthree.svg'
import supporterfour from '../Image/supporterfour.svg'
import supporterfive from '../Image/supporterfive.svg'
import embedded from '../Image/embedded.svg'
import electronics from '../Image/electronics.gif'
import internetthings from '../Image/Internetthings.svg'
import robotics from '../Image/robotics.svg'
import studentreviewone from '../Image/Reviews-1.svg'
import studentreviewtwo from '../Image/Reviews-2.svg'
import backimage from '../Image/backimage.svg'

const Home = () => {
  return (
    <div>
        <div className='home-first bg-light'>
            <div className='container'>
                <h1 className='mb-3'>नई दिशाएं <span> || नई सोच || </span> नया निर्माण</h1>
                <h4 className='mb-3'>To generate innovative & Creative ideas in the minds of new generations and to introduce those ideas in the form of technology with the help of STEM education.</h4>
                <div className="nav-item">
                    <Link className="btn btn-black" to={"/Courses"} >View Course</Link>
                </div>
            </div>
        </div>
        <div className='container home-tow'>
            <div className='row'>
                <div className='col-12'>
                    <div className='three-box'>
                        <div className='tow-text mb-5'>
                            <h2 className='mb-3'><span>Best Supporter of </span> ElectroEra..</h2>
                        </div>
                        <div className='d-flex flex-wrap justify-content-around'>
                            <div className='col-lg-2 col-md-4 col-sm-6 col-6 text-center my-3'>
                                <img src={supporterone}/>
                            </div>
                            <div className='col-lg-2 col-md-4 col-sm-6 col-6 text-center my-3'>
                                <img src={supportertwo}/>
                            </div>
                            <div className='col-lg-2 col-md-4 col-sm-6 col-6 text-center my-3'>
                                <img src={supporterthree}/>
                            </div>
                            <div className='col-lg-2 col-md-4 col-sm-6 col-6 text-center my-3'>
                                <img src={supporterfour}/>
                            </div>
                            <div className='col-lg-2 col-md-4 col-sm-6 col-6 text-center my-3'>
                                <img src={supporterfive}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container home-tow'>
            <div className='tow-text text-center mb-5'>
                <h2 className='mb-3'><span>Our Student </span>Project On</h2>
            </div>
            <div className='row'>
                <div className='col-lg-3 col-md-6 my-2'>
                    <div className='work-box p-3'>
                        <div className='work-img'>
                            <img className='img-fluid w-100 h-100' src={electronics}/>
                        </div>
                        <h2>Electronics</h2>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 my-2'>
                    <div className='work-box p-3'>
                        <div className='work-img'>
                            <img className='img-fluid w-100 h-100' src={internetthings}/>
                        </div>
                        <h2>Internet of things (IOT)</h2>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 my-2'>
                    <div className='work-box p-3'>
                        <div className='work-img'>
                            <img className='img-fluid w-100 h-100' src={robotics}/>
                        </div>
                        <h2>Robotics</h2>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 my-2'>
                    <div className='work-box p-3'>
                        <div className='work-img'>
                            <img className='img-fluid w-100 h-100' src={embedded}/>
                        </div>
                        <h2>Embedded</h2>
                    </div>
                </div>
            </div>
        </div>
        <div className='container home-tow'>
            <div className='tow-text text-center mb-5'>
                <h2 className='mb-3'><span>Client </span>Review</h2>
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-6 my-2'>
                    <div className='author-box'>
                        <div className='author-img'>
                            <img className='img-fluid' src={studentreviewone}/>
                        </div>
                        <p>ElectroEra has been a game-changer for me and many others. Their emphasis on practical knowledge and industrial exposure sets them apart. I joined their internship program and had hands-on experience in the field of Embedded systems. The guidance and mentorship I received were invaluable. The company's commitment to Indian ideology while embracing global technology trends is truly impressive. ElectroEra is paving the way for the next generation of innovators, and I'm grateful to be a part of it.</p>
                        <h2>Student</h2>
                        <h5>Vivek Makwana</h5>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 my-2'>
                    <div className='author-box'>
                        <div className='author-img'>
                            <img className='img-fluid' src={studentreviewtwo}/>
                        </div>
                        <p>I've always been passionate about technology, and ElectroEra has been the perfect platform for me to nurture that passion. Their wide array of courses and training programs cover everything from Drone Technology to App development. The team's dedication to improving technical education skills is remarkable. The best part is the inclusive environment they create, where diverse ideas flourish. ElectroEra's tagline, "नई दिशाएं || नई सोच || नया निर्माण," truly encapsulates the spirit of this organization.</p>
                        <h2>Real Intent</h2>
                        <h5>Ashish Hari, Indian R&D Head</h5>
                    </div>
                </div>
            </div>
        </div>
        <div className='home-tow'></div>
        <div className='bg-drop drop-tow' style={{backgroundImage: `url(${backimage})`}}>
            <div className='container'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-lg-6 col-md-12 drop-text my-3'>
                        <p>Become A Trainer</p>
                        <h2 className='mb-0'>You can join with <br/>
                            ElectroEra as a Trainer?
                        </h2>
                    </div>
                    <div className='col-lg-4 col-md-12 my-3'>
                        <div className="nav-item">
                            <Link className="btn btn-drop" type="submit" to={"/Contact"} >Connect Now</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='foot-color'>
        </div>
        <div className='bg-dark'>
            <div className='container'>
                <div className='py-2 row justify-content-between'>
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                    <p className='mb-0'> © 2021-2024 ElectroEra PVT LTD. All Rights Reserved.</p>
                    </div>
                    
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                        <p className='mb-0'>Design by <Link target={'_blank'} to={`https://mechodal.com/`}>Mechodal Technology</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Home




{/* <div className='container home-tow'>
<div className='tow-text text-center mb-5'>
    <p className='mb-3'>Over 1,235+ Student joined</p>
    <h2 className='mb-3'><span>How It </span>Work?</h2>
</div>
<div className='row'>
    <div className='col-lg-3 col-md-6 my-2'>
        <div className='tow-box'>
            <div className='midal-text'>
                <h1 className='mb-0'>1</h1>
            </div>
            <h2>Find Your Course</h2>
            <p className='mb-0'>It has survived not only centurie also leap into electronic.</p>
        </div>
    </div>
    <div className='col-lg-3 col-md-6 my-2'>
        <div className='tow-box'>
            <div className='midal-text'>
                <h1 className='mb-0'>2</h1>
            </div>
            <h2>Book A Seat</h2>
            <p className='mb-0'>It has survived not only centurie also leap into electronic.</p>
        </div>
    </div>
    <div className='col-lg-3 col-md-6 my-2'>
        <div className='tow-box'>
            <div className='midal-text'>
                <h1 className='mb-0'>3</h1>
            </div>
            <h2>Gain knowledge </h2>
            <p className='mb-0'>It has survived not only centurie also leap into electronic.</p>
        </div>
    </div>
    <div className='col-lg-3 col-md-6 my-2'>
        <div className='tow-box'>
            <div className='midal-text'>
                <h1 className='mb-0'>4</h1>
            </div>
            <h2>Get Certificate</h2>
            <p className='mb-0'>It has survived not only centurie also leap into electronic.</p>
        </div>
    </div>
</div>
</div> */}
