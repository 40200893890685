import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';


import backimage from '../Image/backimage.svg'
import Imgapi from '../Imagedata/Imgapi'
import Menucard from '../Basic/Menucard';


const Gallery = () => {
    
    
  const [activeButton, setActiveButton] = useState('all');

    const [imgData,setImgData]=useState(Imgapi);

    const filterItem = (category) =>{
        const updList = Imgapi.filter((currElem)=>{
            return currElem.category === category;
        })
        setImgData(updList);      
    setActiveButton(category);
    }

  return (
    <div>
        <div className='about-first bg-light'>
            <div className='container'>
                <p className='mb-3'><Link className='text-dark link-home' to={`/home`}>Home // </Link><span>Gallery</span></p>
                <h1 className='mb-3'><span>Gallery </span>ElectroEra</h1>
            </div>
        </div>
        <div className='container home-tow'>
            <div className='button-main'>
                <button onClick={() => { setImgData(Imgapi); setActiveButton('all'); }} className={activeButton === 'all' ? 'active' : ''}>All</button>
                <button onClick={() => filterItem("Workshop")} className={activeButton === 'Workshop' ? 'active' : ''}>Workshop</button>
                <button onClick={() => filterItem("Labsetup")} className={activeButton === 'Labsetup' ? 'active' : ''}>Lab Setup</button>
                <button onClick={() => filterItem("Celebration")} className={activeButton === 'Celebration' ? 'active' : ''}>Celebration</button>
                <button onClick={() => filterItem("Projects")} className={activeButton === 'Projects' ? 'active' : ''}>Projects</button>
            </div>
        </div>

        {/* <div className='container home-tow'>
            <div className='button-main'>
                <button onClick={()=>setImgData(Imgapi)} className={activeButton === 'all' ? 'active' : ''}>All</button>
                <button onClick={()=>filterItem("Workshop")}  className={activeButton === 'Workshop' ? 'active' : ''}>Workshop</button>
                <button onClick={()=>filterItem("Labsetup")}  className={activeButton === 'Labsetup' ? 'active' : ''}>Lab Setup</button>
                <button onClick={()=>filterItem("Celebration")}  className={activeButton === 'Celebration' ? 'active' : ''}>Celebration</button>
                <button onClick={()=>filterItem("Projects")}  className={activeButton === 'Projects' ? 'active' : ''}>Projects</button>
            </div>
        </div> */}

        <div className='container home-tow'>
            <div className='row'>              
                <Menucard imgData={imgData}/>
            </div>
        </div>

        <div className='home-tow'></div>
        <div className='foot-color'>
        </div>
        <div className='bg-dark'>
            <div className='container'>
                <div className='py-2 row justify-content-between'>
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                    <p className='mb-0'> © 2021-2024 ElectroEra PVT LTD. All Rights Reserved.</p>
                    </div>
                    
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                        <p className='mb-0'>Design by <Link target={'_blank'} to={`https://mechodal.com/`}>Mechodal Technology</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Gallery
