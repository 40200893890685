import React from 'react'
import { NavLink } from 'react-router-dom';
import logo from '../logo.svg'
import { HashLink as Link } from 'react-router-hash-link';

const Navbar = () => {
  return (
    <div>
        {/* nav fixx */}
        <div className='fixx'>
            <div className="marquee">
                <div>
                    <span className='line-none'>For More Information and Detail call us here : <b><Link to={"tel:+919409005693"}>+91 94090 05693</Link></b></span>
                    <span>For More Information and Detail call us here : <b><Link to={"tel:+919409005693"}>+91 94090 05693</Link></b></span>
                </div>
            </div>
            <div className='bg-dark d-md-block d-none'>
                <div className='container'>
                    <div className='py-2 row justify-content-between align-items-center'>
                        <div className='col-lg-6 col-md-5 d-md-block d-none nav-p'>
                            <p className='mb-0'>All course 21% off for <a href='#'>GTU Student’s</a></p>
                        </div>
                        <div className='col-lg-6 col-md-7 d-md-block d-none'>
                            <div className='d-flex justify-content-end call-nav'>
                                <a className='me-5' href='tel:+917778970606'><i className="fa-solid fa-phone me-2"></i>+91 7778970606</a>
                                <a href='mailto:info.electroera@gmail.com'><i className="fa-solid fa-envelope me-2"></i>info.electroera@gmail.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg sticky-top navbar-light bg-light">
                <div className="py-2 container">
                    <NavLink to={"Home"} className="navbar-brand">
                        <img style={{width:'12rem'}} src={logo}/>
                    </NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse navheight justify-content-end" id="navbarSupportedContent">
                    <ul className="navbar-nav navbarbottom mb-lg-0 align-items-lg-center">
                        <li className="nav-item">
                        <NavLink className="nav-link" aria-current="page" to={"Home"} >Home</NavLink>
                        </li>
                        <li className="nav-item">
                        <NavLink className="nav-link" aria-current="page" to={"About-Us"} >About Us</NavLink>
                        </li>
                        <li className="nav-item">
                        <NavLink className="nav-link" aria-current="page" to={"Service"} >Services</NavLink>
                        </li>
                        <li className="nav-item">
                        <NavLink className="nav-link" aria-current="page" to={"Project"} >Projects</NavLink>
                        </li>
                        <li className="nav-item">
                        <NavLink className="nav-link" aria-current="page" to={"Courses"} >Other Activities</NavLink>
                        </li>
                        <li className="nav-item">
                        <NavLink className="nav-link" aria-current="page" to={"Gallery"} >Gallery</NavLink>
                        </li>
                        <li className="nav-item">
                        <NavLink className="nav-link" aria-current="page" to={"Blog"} >Blog</NavLink>
                        </li>
                        <li className="nav-item">
                        <NavLink className="btn btn-yellow cont-buton" type="submit" to={"Contact"} >Contact us</NavLink>
                        </li>
                    </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>
  )
}

export default Navbar


                        // <li className="nav-item dropdown">
                        //     <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        //     Services
                        //     </a>
                        //     <ul className="dropdown-menu eui-main p-3" aria-labelledby="navbarDropdown">
                        //         <ul className='training-option ps-0'>
                        //             <li className='two mb-2'><Link to={"Service/#Equipment"} smooth><a href="#">Equipment Supply</a></Link></li>
                        //             <li className="train mb-2"><a>Training <i className="fa-solid fa-caret-down training ms-1"></i></a>
                        //                 <ul className="eui-main px-3 py-0" aria-labelledby="navbarDropdownn">
                        //                     <ul className='training-option ps-0'>
                        //                         <li className='two my-2'><Link to={"Service/#Teacher"} smooth><a href="#">Teachers Training</a></Link></li>
                        //                         <li className='two mb-2'><Link to={"Service/#Student"} smooth><a href="#">Students Training</a></Link></li>
                        //                     </ul>
                        //                 </ul>
                        //             </li>
                        //             <li className='two mb-2'><Link to={"Service/#Master"} smooth><a href="#">Master Trainer</a></Link></li>
                        //             <li className='two mb-2'><Link to={"Service/#Lebsetup"} smooth><a href="#">Leb Set-Up</a></Link></li>
                        //         </ul>
                        //     </ul>
                        // </li>   

                        {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Services
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href="#">Equipment Supply</a></li>
                                <li><a className="dropdown-item nav-link dropdown-toggle" href="#" id="navbarDropdownsec">Training</a></li>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownsec">
                                        <li><a className="dropdown-item" href="#">Equipment Supply</a></li>
                                        <li><a className="dropdown-item" href="#">Training</a></li>
                                    </ul>
                                <li><a className="dropdown-item" href="#">Mater Trainer</a></li>
                                <li><a className="dropdown-item" href="#">Leb Set-Up</a></li>
                            </ul>
                        </li> */}


                        
                        {/* <li className="nav-item">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"  aria-expanded="false" aria-label="Toggle navigation">
		                        <span className="navbar-toggler-icon"></span>
	                        </button>
                            <div className="collapse navbar-collapse" id="main_nav">
                                <ul className="navbar-nav">
                                    
                                </ul>
                            </div>
                        </li> */}