import React from 'react'
import features from '../Image/features.svg'
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import backimage from '../Image/backimage.svg'

const Digitalmarketing = () => {
  return (
    <>
    <div className='about-first bg-light'>
            <div className='container'>
            <p className='mb-3 text-dark'><Link className='text-dark link-home' to={`/home`}>Home // </Link><span>Paid Course </span> // <span>Workshop </span> // <span> Digital marketing</span></p>
                <h1 className='mb-3'><span>Digital marketing </span></h1>
            </div>
    </div>
    <div className='container home-tow'>
            <div className='row '>
                <div className='col-lg-6 col-md-10 my-3'>
                    <div className='about-img'>
                      <img className='img-fluid' src={features}/>
                    </div>
                </div>
                <div className='col-lg-6 col-md-12 my-3'>
                    <div className='about-sec'>
                        <h5>We Provide Technical Training</h5>
                        <p>Our student training programs are designed to inspire and prepare the next generation of technology professionals. Through hands-on learning experiences and practical projects, we offer training in various technical disciplines such as Electronics, Internet of Things, 3D Design & Printing, Robotics, Drone Technology, App development, Programming, AR/VR Technology, Embedded system etc. Our topic-based training programs cater to specific areas of interest within the technology field. Whether you're interested in mobile app development, machine learning, Artificial intelligence, or any other cutting-edge topic, our specialized courses provide in-depth knowledge and hands-on practice to help you master the subject matter.</p>
                    </div>
                </div>
            </div>
        </div>
    <div className='container home-tow'>
            <div className='row '>
                <div className='col-lg-6 col-md-12 my-3'>
                    <div className='about-sec'>
                        <h5>We Provide Technical Training</h5>
                        <p>Our student training programs are designed to inspire and prepare the next generation of technology professionals. Through hands-on learning experiences and practical projects, we offer training in various technical disciplines such as Electronics, Internet of Things, 3D Design & Printing, Robotics, Drone Technology, App development, Programming, AR/VR Technology, Embedded system etc. Our topic-based training programs cater to specific areas of interest within the technology field. Whether you're interested in mobile app development, machine learning, Artificial intelligence, or any other cutting-edge topic, our specialized courses provide in-depth knowledge and hands-on practice to help you master the subject matter.</p>
                    </div>
                </div>
                <div className='col-lg-6 col-md-10 my-3'>
                    <div className='about-img'>
                      <img className='img-fluid' src={features}/>
                    </div>
                </div>
            </div>
        </div>
        
    <div className='home-tow'></div>

        <div className='foot-color'>
        </div>
        <div className='bg-dark'>
            <div className='container'>
                <div className='py-2 row justify-content-between'>
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                    <p className='mb-0'> © 2021-2024 ElectroEra PVT LTD. All Rights Reserved.</p>
                    </div>
                    
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                        <p className='mb-0'>Design by <Link target={'_blank'} to={`https://mechodal.com/`}>Mechodal Technology</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Digitalmarketing