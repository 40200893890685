import React from 'react'
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "../Blog.css";

const Blog = () => {
  return (
    <div>
        <div className='about-first bg-light'>
            <div className='container'>
                <p className='mb-3'><Link className='text-dark link-home' to={`/home`}>Home // </Link><span>Blog</span></p>
                <h1 className='mb-3'><span>Our </span>Blog</h1>
            </div>
        </div>

<section class="py-44">
    <div class="container">
        <div class="blog-section blog-one-bg">
            <div class="row align-items-center">
                <div class="col-lg-8 my-2">
                    <div class="service-section-one">
                        <div class="four-dot">
                            <span class="blog-dot-one"></span>
                            <span class="blog-dot-two"></span>
                            <span class="blog-dot-three"></span>
                            <span class="blog-dot-four"></span>
                        </div>
                        <h1>What is STEM Education?</h1>
                        <p>STEM education, an acronym for Science, Technology, Engineering, and Mathematics, is a comprehensive approach to learning and teaching these subjects in an interdisciplinary and applied manner. It integrates these four disciplines into a cohesive learning paradigm, fostering critical thinking, problem-solving, innovation, and creativity among students.</p>
                    </div>
                </div>
                <div class="col-lg-4 my-2">
                </div>
                <div class="col-12">
                    <div class="blog-read-more">
                        <div class="by-date">
                            <div class="date-time-blog">
                                <p><i class="fa-regular fa-clock"></i> <Link to={''}> Jan 21 2024</Link>, <span>STEM</span></p>
                            </div>
                            <div class="date-time-blog">
                                <p><i class="fa-regular fa-user"></i> By <Link to={''}> Gahan Gosai</Link></p>
                            </div>
                        </div>
                        <div class="read-more-btn">
                            <Link to={'/blogdetail1'} class="btn btn-dark my-1">Read in Detail <i class="fa-solid fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="blog-section blog-two-bg">
            <div class="row align-items-center">
                <div class="col-lg-8 my-2">
                    <div class="service-section-one blog-two-color">
                        <div class="four-dot">
                            <span class="blog-dot-one"></span>
                            <span class="blog-dot-two"></span>
                            <span class="blog-dot-three"></span>
                            <span class="blog-dot-four"></span>
                        </div>
                        <h1>The Impact of STEM Education in Student Life</h1>
                        <p>STEM education plays a pivotal role in shaping the lives of students by equipping them with essential skills, knowledge, and competencies that are crucial for success in the modern world. Its impact extends far beyond the classroom, influencing various aspects of student life and shaping their future career prospects.</p>
                    </div>
                </div>
                <div class="col-lg-4 my-2">
                </div>
                <div class="col-12">
                    <div class="blog-read-more">
                        <div class="by-date">
                            <div class="date-time-blog blog-two-color">
                                <p><i class="fa-regular fa-clock"></i> <Link to={''}> Feb 01 2024</Link>, <span>STEM</span></p>
                            </div>
                            <div class="date-time-blog blog-two-color">
                                <p><i class="fa-regular fa-user"></i> By <Link to={''}> Gahan Gosai</Link></p>
                            </div>
                        </div>
                        <div class="read-more-btn">
                            <Link to={'/blogdetail2'} class="btn btn-dark my-1">Read in Detail <i class="fa-solid fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="blog-section blog-one-bg">
            <div class="row align-items-center">
                <div class="col-lg-8 my-2">
                    <div class="service-section-one">
                        <div class="four-dot">
                            <span class="blog-dot-one"></span>
                            <span class="blog-dot-two"></span>
                            <span class="blog-dot-three"></span>
                            <span class="blog-dot-four"></span>
                        </div>
                        <h1>How Students Can Improve Critical Thinking</h1>
                        <p>Critical thinking is a fundamental skill that is essential for success in academics, professional endeavors, and everyday life. It involves the ability to analyze information, evaluate arguments, and make informed decisions based on evidence and reasoning. While some students may naturally possess strong critical thinking skills, others can benefit from intentional practice and cultivation.</p>
                    </div>
                </div>
                <div class="col-lg-4 my-2">
                </div>
                <div class="col-12">
                    <div class="blog-read-more">
                        <div class="by-date">
                            <div class="date-time-blog">
                                <p><i class="fa-regular fa-clock"></i> <Link to={''}> Feb 10 2024</Link>, <span>Critical Thinking</span></p>
                            </div>
                            <div class="date-time-blog">
                                <p><i class="fa-regular fa-user"></i> By <Link to={''}> Gahan Gosai</Link></p>
                            </div>
                        </div>
                        <div class="read-more-btn">
                            <Link to={'/blogdetail3'} class="btn btn-dark my-1">Read in Detail <i class="fa-solid fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



        <div className='foot-color'>
        </div>
        <div className='bg-dark'>
            <div className='container'>
                <div className='py-2 row justify-content-between'>
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                    <p className='mb-0'> © 2021-2024 ElectroEra PVT LTD. All Rights Reserved.</p>
                    </div>
                    
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                        <p className='mb-0'>Design by <Link target={'_blank'} to={`https://mechodal.com/`}>Mechodal Technology</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Blog