import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './components/Home';
import About from './components/About';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Project from './components/Project';
import Teachertraining from './components/Teachertraining';
import Studenttraining from './components/Studenttraining';
import Mastertraining from './components/Mastertraining';
import Labsetup from './components/Labsetup';
import Electronics from './components/Electronics';
import Robotics from './components/Robotics';
import Iots from './components/Iots';
import Blog from './components/Blog';
import Blogdetail1 from './components/Blogdetail1';
import Blogdetail2 from './components/Blogdetail2';
import Blogdetail3 from './components/Blogdetail3';
import Arduino from './components/Arduino';
import Raspberry from './components/Raspberry';
import Workrobotics from './components/Workrobotics';
import Drown from './components/Drown';
import Threedesign from './components/Threedesign';
import Blockcoding from './components/Blockcoding';
import Appdevelopment from './components/Appdevelopment';
import Arvetech from './components/Arvetech';
import Digitalmarketing from './components/Digitalmarketing';
import Arvsseminar from './components/Arvsseminar';
import Aimlseminar from './components/Aimlseminar';
import Embedsystem from './components/Embedsystem';
import Service from './components/Service';
import Courses from './components/Courses';
import Workshop from './components/Workshop';
import Seminar from './components/Seminar';
import Scrolltotop from './Scrolltotop';


function App() {
  return (
    <div className="App">
      <Router>
      <Scrolltotop/>
      <Navbar/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/Home' element={<Home/>}/>
          <Route path='/About-Us' element={<About/>}/>
          <Route path='/Gallery' element={<Gallery/>}/>
          <Route path='/Contact' element={<Contact/>}/>
          <Route path='/Project' element={<Project/>}/>
          <Route path='/Teachertraining' element={<Teachertraining/>}/>
          <Route path='/Studenttraining' element={<Studenttraining/>}/>
          <Route path='/Mastertraining' element={<Mastertraining/>}/>
          <Route path='/Labsetup' element={<Labsetup/>}/>
          <Route path='/Electronics' element={<Electronics/>}/>
          <Route path='/Robotics' element={<Robotics/>}/>
          <Route path='/Iots' element={<Iots/>}/>
          <Route path='/Arduino' element={<Arduino/>}/>
          <Route path='/Raspberry' element={<Raspberry/>}/>
          <Route path='/Workrobotics' element={<Workrobotics/>}/>
          <Route path='/Drown' element={<Drown/>}/>
          <Route path='/Threedesign' element={<Threedesign/>}/>
          <Route path='/Blockcoding' element={<Blockcoding/>}/>
          <Route path='/Appdevelopment' element={<Appdevelopment/>}/>
          <Route path='/Arvetech' element={<Arvetech/>}/>
          <Route path='/Digitalmarketing' element={<Digitalmarketing/>}/>
          <Route path='/Arvsseminar' element={<Arvsseminar/>}/>
          <Route path='/Aimlseminar' element={<Aimlseminar/>}/>
          <Route path='/Embedsystem' element={<Embedsystem/>}/>
          <Route path='/Service' element={<Service/>}/>
          <Route path='/Courses' element={<Courses/>}/>
          <Route path='/Workshop' element={<Workshop/>}/>
          <Route path='/Seminar' element={<Seminar/>}/>
          <Route path='/Blog' element={<Blog/>}/>
          <Route path='/Blogdetail1' element={<Blogdetail1/>}/>
          <Route path='/Blogdetail2' element={<Blogdetail2/>}/>
          <Route path='/Blogdetail3' element={<Blogdetail3/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
