import React from 'react'
import features from '../Image/features.svg'
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import backimage from '../Image/backimage.svg'
import pro1 from '../Image/home-automation.jpg'
import pro2 from '../Image/kitchen-gardening.jpg'
import pro3 from '../Image/Remote-controlled.jpg'
import pro4 from '../Image/Smart-Glasses.jpg'
import pro5 from '../Image/Anti-Sleep-Alarm.jpg'
import pro6 from '../Image/Staircase-System.jpg'
import pro7 from '../Image/Digital-Ludo.jpg'
import pro8 from '../Image/Visitor-Counting.jpg'
import pro9 from '../Image/Smart-Display.jpg'
import pro10 from '../Image/Wireless-Notice.jpg'
import pro11 from '../Image/Line-Following.jpg'
import pro12 from '../Image/Robotics-Arm.jpg'
import pro13 from '../Image/Smart-Parking.jpg'

const Project = () => {
  return (
    <>
    <div className='about-first bg-light'>
            <div className='container'>
                <p className='mb-3'><Link className='text-dark link-home' to={`/home`}>Home // </Link><span>Project</span></p>
                <h1 className='mb-3'><span>Our </span> Projects</h1>
            </div>
    </div>
    
        
    <div className='container home-tow'>
            <div className='row'>
                <div className='col-lg-4 col-md-6 mb-5'>
                    <div>
                        <div className='about-sec tow-boxx'>
                            <div>
                                <img className='img-fluid' src={pro1}/>
                            </div>
                            <h5 className='pt-3 text-start'>Home Automation</h5>
                            <p className='mb-0 pt-2 mt-0 text-justify'>Home automation refers to the use of technology to control and automate various
                        devices and systems within a household. The goal of home automation is to enhance
                        the convenience, comfort, efficiency, and security of a home by allowing homeowners
                        to remotely monitor and control various functions using electronic devices. This can
                        include lighting, heating, cooling, security systems, entertainment systems,
                        appliances, and more.
                        </p>
                        <NavLink to="/Contact" className='round-contact'>
                            <h1 className='mb-0'><i class="fa-solid fa-arrow-right"></i></h1>
                        </NavLink>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-5'>
                    <div>
                        <div className='about-sec tow-boxx'>
                            <div>
                                <img className='img-fluid' src={pro2}/>
                            </div>
                            <h5 className='pt-3 text-start'>Smart irrigation system for kitchen gardening</h5>
                            <p className='mb-0 pt-2 mt-0 text-justify'>A smart irrigation system for kitchen gardening is a technologically advanced solution
designed to efficiently water and nurture plants in a kitchen garden or small-scale
agricultural setting. It leverages various sensors, controllers, and automation
technologies to provide the right amount of water to plants while minimizing waste
and conserving resources. This system aims to enhance the health and productivity
of plants, making gardening more convenient and environmentally friendly.

</p>
                        <NavLink to="/Contact" className='round-contact'>
                            <h1 className='mb-0'><i class="fa-solid fa-arrow-right"></i></h1>
                        </NavLink>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-5'>
                    <div>
                        <div className='about-sec tow-boxx'>
                            <div>
                                <img className='img-fluid' src={pro3}/>
                            </div>
                            <h5 className='pt-3 text-start'>Remote-controlled (RC) car</h5>
                            <p className='mb-0 pt-2 mt-0 text-justify'>The RC car project aims to create a functional and entertaining miniature vehicle that can be
controlled remotely. Participants will assemble the car, integrate the required electronic components,
and learn how to operate it using the provided remote control. The project can be tailored to different
skill levels, from beginners looking for a basic introduction to more advanced enthusiasts seeking
customization and performance enhancements.

</p>
                        <NavLink to="/Contact" className='round-contact'>
                            <h1 className='mb-0'><i class="fa-solid fa-arrow-right"></i></h1>
                        </NavLink>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-5'>
                    <div>
                        <div className='about-sec tow-boxx'>
                            <div>
                                <img className='img-fluid' src={pro4}/>
                            </div>
                            <h5 className='pt-3 text-start'>Smart Glasses For Blind People</h5>
                            <p className='mb-0 pt-2 mt-0 text-justify'>The goal of the Smart Glasses for Blind People project is to create a wearable device that uses
cutting-edge technology to enhance the mobility, independence, and overall quality of life for
individuals who are blind or visually impaired. The project involves designing and engineering a pair
of smart glasses that can sense the environment, process visual information, and convey important
details audibly to the user.

</p>
                        <NavLink to="/Contact" className='round-contact'>
                            <h1 className='mb-0'><i class="fa-solid fa-arrow-right"></i></h1>
                        </NavLink>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-5'>
                    <div>
                        <div className='about-sec tow-boxx'>
                            <div>
                                <img className='img-fluid' src={pro5}/>
                            </div>
                            <h5 className='pt-3 text-start'>Anti Sleep Alarm System</h5>
                            <p className='mb-0 pt-2 mt-0 text-justify'>The primary objective of the Anti Sleep Alarm System project is to create a technology-driven
solution that promotes road safety by addressing the dangers associated with driving while fatigued.
This project involves designing a compact and user-friendly device that can monitor the driver's
physical and behavioral indicators of drowsiness and initiate appropriate warnings if necessary.
</p>
                        <NavLink to="/Contact" className='round-contact'>
                            <h1 className='mb-0'><i class="fa-solid fa-arrow-right"></i></h1>
                        </NavLink>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-5'>
                    <div>
                        <div className='about-sec tow-boxx'>
                            <div>
                                <img className='img-fluid' src={pro7}/>
                            </div>
                            <h5 className='pt-3 text-start'>Digital Ludo Game</h5>
                            <p className='mb-0 pt-2 mt-0 text-justify'>The primary objective of the Digital Ludo Game project is to recreate the traditional Ludo board game
in a digital format. The project involves designing the game's mechanics, graphics, and user interface,
as well as programming the game logic and interactions.
</p>
                        <NavLink to="/Contact" className='round-contact'>
                            <h1 className='mb-0'><i class="fa-solid fa-arrow-right"></i></h1>
                        </NavLink>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-5'>
                    <div>
                        <div className='about-sec tow-boxx'>
                            <div>
                                <img className='img-fluid' src={pro8}/>
                            </div>
                            <h5 className='pt-3 text-start'>Visitor Counting Machine</h5>
                            <p className='mb-0 pt-2 mt-0 text-justify'>The main objective of the Visitor Counting Machine project is to develop a reliable and efficient
system that accurately counts the number of people entering and leaving a designated area. The
system utilizes sensors, data processing algorithms, and user interfaces to provide real-time and
historical information about visitor traffic.

</p>
                        <NavLink to="/Contact" className='round-contact'>
                            <h1 className='mb-0'><i class="fa-solid fa-arrow-right"></i></h1>
                        </NavLink>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-5'>
                    <div>
                        <div className='about-sec tow-boxx'>
                            <div>
                                <img className='img-fluid' src={pro9}/>
                            </div>
                            <h5 className='pt-3 text-start'>Smart Display</h5>
                            <p className='mb-0 pt-2 mt-0 text-justify'>The primary goal of the Smart Display project is to design and build a digital screen or display that
can showcase content, respond to user input, and offer a personalized and engaging experience. This
project involves selecting display technology, developing user interfaces, programming interactions,
and integrating multimedia content.

</p>
                        <NavLink to="/Contact" className='round-contact'>
                            <h1 className='mb-0'><i class="fa-solid fa-arrow-right"></i></h1>
                        </NavLink>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-5'>
                    <div>
                        <div className='about-sec tow-boxx'>
                            <div>
                                <img className='img-fluid' src={pro10}/>
                            </div>
                            <h5 className='pt-3 text-start'>Wireless Notice Board</h5>
                            <p className='mb-0 pt-2 mt-0 text-justify'>The main objective of the Wireless Notice Board project is to create a digital display system that can
replace traditional physical notice boards with a more dynamic and efficient solution. The project
involves developing a hardware setup, implementing wireless communication protocols, and
designing a user-friendly interface for sending and displaying messages.

</p>
                        <NavLink to="/Contact" className='round-contact'>
                            <h1 className='mb-0'><i class="fa-solid fa-arrow-right"></i></h1>
                        </NavLink>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-5'>
                    <div>
                        <div className='about-sec tow-boxx'>
                            <div>
                                <img className='img-fluid' src={pro11}/>
                            </div>
                            <h5 className='pt-3 text-start'>Line Following Robot</h5>
                            <p className='mb-0 pt-2 mt-0 text-justify'>The primary objective of the Line Following Robot project is to create a robot that can move along a
predefined path by detecting and following a visible line on the surface. The project requires selecting
appropriate sensors, programming algorithms for line detection and robot movement, and assembling
the robot's mechanical components.
</p>
                        <NavLink to="/Contact" className='round-contact'>
                            <h1 className='mb-0'><i class="fa-solid fa-arrow-right"></i></h1>
                        </NavLink>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-5'>
                    <div>
                        <div className='about-sec tow-boxx'>
                            <div>
                                <img className='img-fluid' src={pro12}/>
                            </div>
                            <h5 className='pt-3 text-start'>Robotics Arm</h5>
                            <p className='mb-0 pt-2 mt-0 text-justify'>The main goal of the Robotics Arm project is to design and build a robotic arm that can replicate the
movement capabilities of a human arm. The project requires selecting appropriate materials, motors,
sensors, and controllers to create a versatile and precise robotic manipulator.

</p>
                        <NavLink to="/Contact" className='round-contact'>
                            <h1 className='mb-0'><i class="fa-solid fa-arrow-right"></i></h1>
                        </NavLink>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-5'>
                    <div>
                        <div className='about-sec tow-boxx'>
                            <div>
                                <img className='img-fluid' src={pro13}/>
                            </div>
                            <h5 className='pt-3 text-start'>Smart Parking System</h5>
                            <p className='mb-0 pt-2 mt-0 text-justify'>The primary goal of the Smart Parking System project is to design and implement a system that helps
drivers locate available parking spots quickly and efficiently. The project involves installing sensors,
developing software algorithms, and creating user interfaces that provide real-time parking
information to drivers.</p>
                        <NavLink to="/Contact" className='round-contact'>
                            <h1 className='mb-0'><i class="fa-solid fa-arrow-right"></i></h1>
                        </NavLink>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-5'>
                    <div>
                        <div className='about-sec tow-boxx'>
                            <div>
                                <img className='img-fluid' src={pro6}/>
                            </div>
                            <h5 className='pt-3 text-start'>Staircase System</h5>
                            <p className='mb-0 pt-2 mt-0 text-justify'>The primary goal of the Staircase System project is to create a well-designed and structurally sound
staircase that provides a safe and efficient means of vertical circulation within a building. This project
involves conceptualizing, planning, designing, and constructing the staircase while considering factors
such as space utilization, user comfort, safety regulations, and architectural harmony.
</p>
                        <NavLink to="/Contact" className='round-contact'>
                            <h1 className='mb-0'><i class="fa-solid fa-arrow-right"></i></h1>
                        </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    <div className='home-tow'></div>

        <div className='foot-color'>
        </div>
        <div className='bg-dark'>
            <div className='container'>
                <div className='py-2 row justify-content-between'>
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                    <p className='mb-0'> © 2021-2024 ElectroEra PVT LTD. All Rights Reserved.</p>
                    </div>
                    
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                        <p className='mb-0'>Design by <Link target={'_blank'} to={`https://mechodal.com/`}>Mechodal Technology</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Project