import React from 'react'
import galleryone from '../Image/galleryone.svg'
import gallerytwo from '../Image/gallerytwo.svg'
import gallerythree from '../Image/robotics.svg'
import galleryfour from '../Image/galleryfour.svg'
import galleryfive from '../Image/galleryfive.svg'
import gallerysix from '../Image/gallerysix.svg'
import galleryseven from '../Image/galleryseven.svg'
import galleryeight from '../Image/galleryeight.svg'
import gallerynine from '../Image/gallerynine.svg'
import Work from '../Image/work1.png'

const Menucard = ({imgData}) => {
  return (
    <>
   
    {
        imgData.map((curElem)=>{
            return(               
                <div className='col-lg-4 col-md-6 mb-3' key={curElem.id}>
                    <div className='work-box p-0 border-0'>
                        <div className='work-img'>
                            <img className='img-fluid h-auto rounded-0' src={curElem.image}/>
                        <div className='imgdetail rounded-0'>
                            <h4 className='mb-0'>{curElem.heading}</h4>
                        </div>
                        </div>
                    </div>
                </div>
            )
        })
    }
        
        {/* <div className='container home-tow'>
            <div className='row'>
                <div className='col-lg-4 col-md-6 mb-3'>
                    <div className='work-box'>
                        <div className='work-img'>
                            <img className='img-fluid' src={Work}/>
                        <div className='imgdetail'>
                            <h4>Robotics</h4>
                        </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-3'>
                    <div className='work-box'>
                        <div className='work-img'>
                            <img className='img-fluid' src={gallerytwo}/>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-3'>
                    <div className='work-box'>
                        <div className='work-img'>
                            <img className='img-fluid' src={gallerythree}/>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-3'>
                    <div className='work-box'>
                        <div className='work-img'>
                            <img className='img-fluid' src={galleryfour}/>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-3'>
                    <div className='work-box'>
                        <div className='work-img'>
                            <img className='img-fluid' src={galleryfive}/>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-3'>
                    <div className='work-box'>
                        <div className='work-img'>
                            <img className='img-fluid' src={gallerysix}/>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-3'>
                    <div className='work-box'>
                        <div className='work-img'>
                            <img className='img-fluid' src={galleryseven}/>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-3'>
                    <div className='work-box'>
                        <div className='work-img'>
                            <img className='img-fluid' src={galleryeight}/>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-md-6 mb-3'>
                    <div className='work-box'>
                        <div className='work-img'>
                            <img className='img-fluid' src={gallerynine}/>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </>
  )
}

export default Menucard