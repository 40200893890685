import React from 'react'
import features from '../Image/features.svg'
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import backimage from '../Image/backimage.svg'
import Contact from './Contact';

const Seminar = () => {
  return (
    <>
    <div className='about-first bg-light'>
        <div className='container'>
            <p className='mb-3 text-dark'><Link className='text-dark link-home' to={`/home`}>Home // </Link><span>Our Seminar </span></p>
            <h1 className='mb-3'><span>Our </span>Seminar</h1>
        </div>
    </div>
    <div className='container home-tow' id='DigitalMarketing'>
        <div className='tow-text text-center mb-lg-5 mb-sm-3'>
            <h2 className='mb-3'><span>Digital </span>Marketing</h2>
        </div>
        <div className='row '>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>The Digital Marketing course equips students with the strategies and tools needed to succeed in the dynamic world of online marketing. They learn about social media marketing, search engine optimization, content creation, and data analytics.</p>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow'>
        <div className='row '>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>The Digital Marketing course equips students with the strategies and tools needed to succeed in the dynamic world of online marketing. They learn about social media marketing, search engine optimization, content creation, and data analytics.</p>
                </div>
            </div>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow' id='ARVR'>
        <div className='tow-text text-center mb-lg-5 mb-sm-3'>
            <h2 className='mb-3'><span>AR / VR </span>Tech</h2>
        </div>
        <div className='row '>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>Our AR/VR Tech course introduces students to the exciting realms of augmented reality and virtual reality. They learn to create immersive experiences, develop 3D environments, and interact with virtual objects.</p>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow'>
        <div className='row '>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>In the AR/VR course, students dive deeper into augmented reality and virtual reality technologies. They explore advanced concepts such as spatial computing, gesture recognition, and immersive storytelling.</p>
                </div>
            </div>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow' id='ATML'>
        <div className='tow-text text-center mb-lg-5 mb-sm-3'>
            <h2 className='mb-3'><span>AI </span>/ ML</h2>
        </div>
        <div className='row '>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>The AI/ML course introduces students to the fascinating fields of artificial intelligence and machine learning. They learn about algorithms, data analysis, and model training, developing the skills to build intelligent systems.</p>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow'>
        <div className='row '>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>The AI/ML course introduces students to the fascinating fields of artificial intelligence and machine learning. They learn about algorithms, data analysis, and model training, developing the skills to build intelligent systems.</p>
                </div>
            </div>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow' id='EmbeddedSystems'>
        <div className='tow-text text-center mb-lg-5 mb-sm-3'>
            <h2 className='mb-3'><span>Embedded </span>Systems</h2>
        </div>
        <div className='row '>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>Our Embedded Systems course focuses on the design and development of embedded systems, combining hardware and software. Students gain hands-on experience in programming microcontrollers, interfacing with sensors and actuators, and building real-time systems.</p>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow'>
        <div className='row '>
            <div className='col-lg-6 col-md-12 my-3'>
                <div className='about-sec'>
                    <p>Our Embedded Systems course focuses on the design and development of embedded systems, combining hardware and software. Students gain hands-on experience in programming microcontrollers, interfacing with sensors and actuators, and building real-time systems.</p>
                </div>
            </div>
            <div className='col-lg-6 col-md-10 my-3'>
                <div className='about-img'>
                    <img className='img-fluid' src={features}/>
                </div>
            </div>
        </div>
    </div>
    
    <div className='home-tow'></div>

        <div className='foot-color'>
        </div>
        <div className='bg-dark'>
            <div className='container'>
                <div className='py-2 row justify-content-between'>
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                    <p className='mb-0'> © 2021-2024 ElectroEra PVT LTD. All Rights Reserved.</p>
                    </div>
                    
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                        <p className='mb-0'>Design by <Link target={'_blank'} to={`https://mechodal.com/`}>Mechodal Technology</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Seminar
