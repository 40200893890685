import React from 'react'
import phone from '../Image/phone.svg'
import email from '../Image/email.svg'
import location from '../Image/location.svg'
import { Link } from 'react-router-dom';

const Contact = () => {
  return (
    <>
    <div class="about-first bg-light">
      <div class="container">
        <p class="mb-3"><a class="text-dark link-home" href="/home">Home // </a><span>Contact Us</span></p>
        <h1 class="mb-3"><span>Contact </span>Us</h1>
      </div>
    </div>

      <div class="container home-tow">
        
        <div class="row m-0  contact-form">   
        <div class="tow-text text-center"><h2 class="mb-3"><span>Get in Touch </span>With Us</h2></div>    
          <div class="col-lg-6 col-md-12 my-3">
            <div class="call-box">
              <div class="d-flex align-items-center border-call py-4">
                <div class="location-img">
                  <img class="img-fluid me-3" src={phone}/>
                </div>
                <div class="phone-css">
                  <p class="mb-0">Phone No.</p><a href="tel:+917778970606">+91 7778970606</a>
                  </div>
                </div>
                <div class="d-flex align-items-center border-call py-4">
                  <div class="location-img">
                    <img class="img-fluid me-3" src={email}/>
                  </div>
                  <div class="phone-css">
                    <p class="mb-0">Email Address.</p>
                    <a href="mailto:info.electroera@gmail.com">info.electroera@gmail.com</a>
                  </div>
                </div>
                <div class="d-flex align-items-center border-call py-4">
                  <div class="location-img">
                      <img class="img-fluid me-3" src={location}/>
                  </div>
                  <div class="phone-css">
                    <p class="mb-0">Our Center's.</p><a href="#">Bhavnagar</a>
                </div>
              </div>
            </div>
          </div>
              <div class="col-lg-6 col-md-12 my-3">
                  <div class="touch call-box py-0 bg-white">
                      {/* <h1>Get in Touch <span>With Us</span></h1> */}
                    <form action='https://theelectroera.com/mail.php' method='POST'>
                      <input class="form-control mb-3" type="text" placeholder="Name" name="name" required/>
                      <input class="form-control mb-3" type="number" placeholder="Mobile" name="mobile" required/>
                      <input class="form-control mb-3" type="text" placeholder="Name Of Institute " name="institute_name" required/>
                      <input class="form-control mb-3" type="text" placeholder="Reference" name="reference" required/>
                      <input class="form-control mb-3" type="email" placeholder="Email" name="email" required/>
                      <input class="form-control mb-3" type="text" placeholder="Purpose" name="purpose" required/>
                      <textarea rows="4" class="form-control mb-3" placeholder='Message ' name="message" required/>
                      <div class="send-msg">
                      <button type="submit" aria-current="page" class="btn btn-yellow w-100 active" href="/Contact-Us">Send Message</button></div>
                    </form>
                </div>
             </div>
          </div>
      </div>
      <div className='home-tow'></div>
      <div className='foot-color'>
      </div>
      <div className='bg-dark'>
          <div className='container'>
              <div className='py-2 row justify-content-between'>
                  <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                  <p className='mb-0'> © 2021-2024 ElectroEra PVT LTD. All Rights Reserved.</p>
                  </div>
                  
                  <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                      <p className='mb-0'>Design by <Link target={'_blank'} to={`https://mechodal.com/`}>Mechodal Technology</Link></p>
                  </div>
              </div>
          </div>
      </div>
    </>
  )
}

export default Contact
