import React from 'react'
import features from '../Image/features.svg'
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import backimage from '../Image/backimage.svg'
import Contact from './Contact';
import Courses1 from '../Image/Courses1.jpg'
import Courses2 from '../Image/Courses2.jpg'
import Courses3 from '../Image/Courses3.jpg'
import Workshop1 from '../Image/Workshop1.jpg'
import Workshop2 from '../Image/Workshop2.jpg'
import Workshop3 from '../Image/Workshop3.jpg'
import Workshop4 from '../Image/Workshop4.jpg'
import Workshop5 from '../Image/Workshop5.jpg'
import Workshop6 from '../Image/Workshop6.jpg'
import Workshop7 from '../Image/Workshop7.png'
import Workshop8 from '../Image/Workshop8.jpg'
import Seminar1 from '../Image/Seminar1.jpg'
import Seminar2 from '../Image/Seminar2.jpg'
import Seminar3 from '../Image/Seminar3.jpg'
import Seminar4 from '../Image/Seminar4.jpg'

const Courses = () => {
  return (
    <>
    <div className='about-first bg-light'>
        <div className='container'>
            <p className='mb-3 text-dark'><Link className='text-dark link-home' to={`/home`}>Home // </Link><span>Other Activities </span></p>
            <h1 className='mb-3'><span>Other </span>Activities</h1>
        </div>
    </div>
    
    <div class="software sticky-top top-header nav-shad bg-dark">
        <div class="container">
            <div class="d-flex header-three">
                <a href="#ourcourses">Our Courses</a>
                <a href="#ourworkshop">Our Workshop</a>
                <a href="#ourseminar">Our Seminar</a>
            </div>
        </div>
    </div>
    
    <div className='about-first bg-light our-first' id='ourcourses'>
        <div className='container'>
            <h1 className='mb-0'><span>Our </span>Courses</h1>
        </div>
    </div>

    <div className='container home-tow home-three'>
        <div className='row '>
            <div className='col-lg-6 col-md-6 my-2'>
                <div className='about-img'>
                    <img className='img-fluid' src={Courses1}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 my-2'>
                <div className='about-sec ps-0 ps-lg-4'>
                    <h3>Electronics</h3>
                    <p>
                        Electronics courses are educational programs that focus on teaching students about the
                        fundamental principles, concepts, and applications of electronics, ranging from basic circuitry
                        to advanced topics in digital systems, communication, and microelectronics. These courses
                        are designed to equip students with the knowledge and skills needed to work with electronic
                        components, design circuits, troubleshoot issues, and understand the workings of various
                        electronic devices.
                    </p>
                    <div className="nav-item">
                    <NavLink className="btn btn-yellow cont-buton" type="submit" to={"/Contact"} >Contact us</NavLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow home-three pt-0'>
        <div className='row '>
            <div className='col-lg-6 col-md-6 my-2 order-2 order-md-1'>
                <div className='about-sec pe-0 pe-lg-4'>
                    <h3>Robotics</h3>
                    <p>
                    Robotics courses are structured educational programs that focus on teaching students about
                    the principles, design, construction, programming, and applications of robots. These courses
                    cover a wide range of topics, from basic concepts to advanced techniques, aiming to equip
                    participants with the skills and knowledge needed to work with robotic systems.
                    </p>
                    <div className="nav-item">
                    <NavLink className="btn btn-yellow cont-buton" type="submit" to={"/Contact"} >Contact us</NavLink>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 my-2 order-1 order-md-2'>
                <div className='about-img'>
                    <img className='img-fluid' src={Courses2}/>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow home-three pt-0'>
        <div className='row '>
            <div className='col-lg-6 col-md-6 my-2'>
                <div className='about-img'>
                    <img className='img-fluid' src={Courses3}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 my-2'>
                <div className='about-sec ps-0 ps-lg-4'>
                    <h3>Internet of things</h3>
                    <p>
                    Internet of Things (IoT) courses are educational programs that focus on teaching students
                    about the concepts, technologies, and applications of the IoT. These courses delve into how
                    everyday objects can be connected to the internet, enabling them to collect and exchange
                    data, make intelligent decisions, and enhance various aspects of life and industry. IoT courses
                    are offered at various levels, from introductory to advanced, catering to individuals with
                    different backgrounds and goals.
                    </p>
                    <div className="nav-item">
                    <NavLink className="btn btn-yellow cont-buton" type="submit" to={"/Contact"} >Contact us</NavLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div className='about-first bg-light our-first' id='ourworkshop'>
        <div className='container'>
            <h1 className='mb-0'><span>Our </span>Workshop</h1>
        </div>
    </div>

    <div className='container home-tow home-three'>
        <div className='row '>
            <div className='col-lg-6 col-md-6 my-2'>
                <div className='about-img'>
                    <img className='img-fluid' src={Workshop1}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 my-2'>
                <div className='about-sec ps-0 ps-lg-4'>
                    <h3>Arduino Based Electronics Workshop</h3>
                    <p>
                    An electronics workshop is an organized and interactive learning session that focuses on
                    teaching participants about various aspects of electronics, from basic concepts to practical
                    hands-on projects. These workshops are designed to provide participants with the knowledge
                    and skills needed to understand, design, build, and troubleshoot electronic circuits and
                    devices. They are typically led by experienced instructors or facilitators and may vary in
                    duration and complexity based on the target audience's skill levels and goals.
                    </p>
                    <div className="nav-item">
                    <NavLink className="btn btn-yellow cont-buton" type="submit" to={"/Contact"} >Contact us</NavLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow home-three pt-0'>
        <div className='row '>
            <div className='col-lg-6 col-md-6 my-2 order-2 order-md-1'>
                <div className='about-sec pe-0 pe-lg-4'>
                    <h3>Arduino Based Robotics Workshop</h3>
                    <p>
                    A robotics workshop is a dynamic and hands-on learning event focused on introducing
participants to the world of robotics, teaching them about robot design, programming, and
practical application. These workshops aim to foster interest, creativity, and problem-solving
skills by providing participants with the opportunity to design, build, and program their own
robots.
                    </p>
                    <div className="nav-item">
                    <NavLink className="btn btn-yellow cont-buton" type="submit" to={"/Contact"} >Contact us</NavLink>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 my-2 order-1 order-md-2'>
                <div className='about-img'>
                    <img className='img-fluid' src={Workshop2}/>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow home-three pt-0'>
        <div className='row '>
            <div className='col-lg-6 col-md-6 my-2'>
                <div className='about-img'>
                    <img className='img-fluid' src={Workshop3}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 my-2'>
                <div className='about-sec ps-0 ps-lg-4'>
                    <h3>ESP8266 Based IoT Workshop</h3>
                    <p>
                    An Internet of Things (IoT) workshop is an educational event designed to introduce
participants to the concept, technologies, and practical applications of the IoT. In an IoT
workshop, participants learn about the interconnected world of devices, sensors, and data
communication, as well as how to build IoT systems and projects.
                    </p>
                    <div className="nav-item">
                    <NavLink className="btn btn-yellow cont-buton" type="submit" to={"/Contact"} >Contact us</NavLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow home-three pt-0'>
        <div className='row '>
            <div className='col-lg-6 col-md-6 my-2 order-2 order-md-1'>
                <div className='about-sec pe-0 pe-lg-4'>
                    <h3>Drone Technology</h3>
                    <p>
                    A Drone Technology workshop is an educational event focused on introducing participants to the
world of drones (unmanned aerial vehicles) and teaching them about the technology, applications,
regulations, and hands-on operation of drones. These workshops aim to provide participants with a
comprehensive understanding of drones and their diverse uses.

                    </p>
                    <div className="nav-item">
                    <NavLink className="btn btn-yellow cont-buton" type="submit" to={"/Contact"} >Contact us</NavLink>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 my-2 order-1 order-md-2'>
                <div className='about-img pe-0 pe-lg-4'>
                    <img className='img-fluid' src={Workshop4}/>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow home-three pt-0'>
        <div className='row '>
            <div className='col-lg-6 col-md-6 my-2'>
                <div className='about-img'>
                    <img className='img-fluid' src={Workshop6}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 my-2'>
                <div className='about-sec ps-0 ps-lg-4'>
                    <h3>Block coding</h3>
                    <p>
                    A block coding workshop is an interactive learning session that introduces participants to
programming using visual blocks or graphical elements instead of traditional text-based code. This
type of workshop is designed to make programming accessible to beginners, including children and
individuals with little to no coding experience. Participants learn how to create programs by arranging
blocks that represent different code commands, enabling them to understand programming logic and
create simple applications.
                    </p>
                    <div className="nav-item">
                    <NavLink className="btn btn-yellow cont-buton" type="submit" to={"/Contact"} >Contact us</NavLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow home-three pt-0'>
        <div className='row '>
            <div className='col-lg-6 col-md-6 my-2 order-2 order-md-1'>
                <div className='about-sec pe-0 pe-lg-4'>
                    <h3>3D Desine & Printing</h3>
                    <p>
                    A 3D Design & Printing Workshop is an educational event that introduces participants to the
concepts, tools, and techniques involved in creating three-dimensional digital models and bringing
them to life through 3D printing technology. This workshop covers various aspects of 3D design,
software usage, and the practical process of turning digital designs into physical objects using 3D
printers.
                    </p>
                    <div className="nav-item">
                    <NavLink className="btn btn-yellow cont-buton" type="submit" to={"/Contact"} >Contact us</NavLink>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 my-2 order-1 order-md-2'>
                <div className='about-img'>
                    <img className='img-fluid' src={Workshop5}/>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow home-three pt-0'>
        <div className='row '>
            <div className='col-lg-6 col-md-6 my-2'>
                <div className='about-img'>
                    <img className='img-fluid' src={Workshop7}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 my-2'>
                <div className='about-sec ps-0 ps-lg-4'>
                    <h3>MIT App Development</h3>
                    <p>
                    A MIT App Development Workshop is an educational program focused on teaching participants how
to create mobile applications using the MIT App Inventor platform. This workshop introduces
individuals, especially those with limited coding experience, to the world of mobile app development
by utilizing a visual programming environment that simplifies the process of building apps for
Android devices.

                    </p>
                    <div className="nav-item">
                    <NavLink className="btn btn-yellow cont-buton" type="submit" to={"/Contact"} >Contact us</NavLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow home-three pt-0'>
        <div className='row '>
            <div className='col-lg-6 col-md-6 my-2 order-2 order-md-1'>
                <div className='about-sec pe-0 pe-lg-4'>
                    <h3>AR/VR Technology</h3>
                    <p>
                    An Augmented Reality (AR) and Virtual Reality (VR) Technology workshop is an immersive and
interactive educational event that introduces participants to the concepts, technologies, and
applications of AR and VR. In these workshops, participants learn about creating and experiencing
digital worlds that merge with or replace the real world, enhancing their understanding of these
cutting-edge technologies.
                    </p>
                    <div className="nav-item">
                    <NavLink className="btn btn-yellow cont-buton" type="submit" to={"/Contact"} >Contact us</NavLink>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 my-2 order-1 order-md-2'>
                <div className='about-img'>
                    <img className='img-fluid' src={Workshop8}/>
                </div>
            </div>
        </div>
    </div>
        
    <div className='about-first bg-light our-first' id='ourseminar'>
        <div className='container'>
            <h1 className='mb-0'><span>Our </span>Seminar</h1>
        </div>
    </div>

    <div className='container home-tow home-three'>
        <div className='row '>
            <div className='col-lg-6 col-md-6 my-2'>
                <div className='about-img'>
                    <img className='img-fluid' src={Seminar1}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 my-2'>
                <div className='about-sec ps-0 ps-lg-4'>
                    <h3>Digital Marketing</h3>
                    <p>
                    A Digital Marketing Seminar is an informative and interactive event that aims to educate participants
about the principles, strategies, and trends in the field of digital marketing. This seminar provides
insights into how businesses and individuals can effectively leverage digital channels and
technologies to reach their target audiences, promote products or services, and achieve their marketing
goals in the online world.

                    </p>
                    <div className="nav-item">
                    <NavLink className="btn btn-yellow cont-buton" type="submit" to={"/Contact"} >Contact us</NavLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow home-three pt-0'>
        <div className='row '>
            <div className='col-lg-6 col-md-6 my-2 order-2 order-md-1'>
                <div className='about-sec pe-0 pe-lg-4'>
                    <h3>AR/VR Technology</h3>
                    <p>
                    An Augmented Reality (AR) and Virtual Reality (VR) Technology seminar is an informative and
engaging event that explores the concepts, technologies, and applications of AR and VR. In these
seminars, participants learn about the immersive worlds created by AR and VR, how they impact
various industries, and the potential they hold for shaping the future of human interaction and
experience.

                    </p>
                    <div className="nav-item">
                    <NavLink className="btn btn-yellow cont-buton" type="submit" to={"/Contact"} >Contact us</NavLink>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 my-2 order-1 order-md-2'>
                <div className='about-img'>
                    <img className='img-fluid' src={Seminar2}/>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow home-three pt-0'>
        <div className='row '>
            <div className='col-lg-6 col-md-6 my-2'>
                <div className='about-img'>
                    <img className='img-fluid' src={Seminar3}/>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 my-2'>
                <div className='about-sec ps-0 ps-lg-4'>
                    <h3>AI/ML Technology</h3>
                    <p>
                    An Artificial Intelligence (AI) and Machine Learning (ML) Technology Seminar is an informational
event that focuses on providing participants with insights into the concepts, advancements, and
applications of AI and ML technologies. These seminars aim to educate attendees about the
capabilities of AI and ML, showcase real-world examples, and discuss the impact of these
technologies on various industries.
                    </p>
                    <div className="nav-item">
                    <NavLink className="btn btn-yellow cont-buton" type="submit" to={"/Contact"} >Contact us</NavLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='container home-tow home-three pt-0'>
        <div className='row '>
            <div className='col-lg-6 col-md-6 my-2 order-2 order-md-1'>
                <div className='about-sec pe-0 pe-lg-4'>
                    <h3>Importance of Technologies</h3>
                    <p>
                    An "Importance of Technology" seminar typically features expert speakers, panel discussions,
presentations, and interactive sessions that delve into different facets of technology's role in our world.
The seminar explores both the benefits and challenges that technology presents, offering a
well-rounded perspective on its influence.
                    </p>
                    <div className="nav-item">
                    <NavLink className="btn btn-yellow cont-buton" type="submit" to={"/Contact"} >Contact us</NavLink>
                    </div>
                </div>
            </div>
            <div className='col-lg-6 col-md-6 my-2 order-1 order-md-2'>
                <div className='about-img'>
                    <img className='img-fluid' src={Seminar4}/>
                </div>
            </div>
        </div>
    </div>

    <div className='home-tow'></div>

        <div className='foot-color'>
        </div>
        <div className='bg-dark'>
            <div className='container'>
                <div className='py-2 row justify-content-between'>
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                    <p className='mb-0'> © 2021-4 ElectroEra PVT LTD. All Rights Reserved.</p>
                    </div>
                    
                    <div className='col-lg-4 col-md-6 text-center my-2 nav-p'>
                        <p className='mb-0'>Design by <Link target={'_blank'} to={`https://mechodal.com/`}>Mechodal Technology</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Courses
